<template>
  <div>
    <app-collapse
      accordion
      type="default"
    >
      <app-collapse-item
        v-for="(list, key) in CourseData[typeCourse].topics"
        :key="key"
        :title="list.name"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(details, llave) in list.courseDetail"
            :key="llave"
            class="d-flex justify-content-between align-items-center"
          >
            <span>{{ details.name }}</span>
            <b-button
              variant="primary"
              size="sm"
              @click="setVideoInfo(details)"
            >
              Ver lección
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </app-collapse-item>
    </app-collapse>
    <b-modal
      v-model="modalShow"
      cancel-variant="outline-secondary"
      ok-title="Login"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="lg"
    >
      <b-row>
        <b-col
          id="responsive-html"
          cols="12"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="dataCourse" />
          <!--eslint-enable-->
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="close()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CourseData from '@/components/course/data'
import {
  BListGroup, BListGroupItem, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    typeCourse: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  data: () => ({
    CourseData,
    modalShow: false,
    dataCourse: null,
  }),
  methods: {
    setVideoInfo(value) {
      this.modalShow = true
      this.dataCourse = value.demoVideoUrl
    },
    close() {
      this.modalShow = false
      this.dataCourse = null
    },
  },
}
</script>
